import { types } from 'mobx-state-tree';

export const BRU = types.model({
  id: types.integer,

  archived: types.boolean,
  cooler_id: types.maybeNull(types.integer),
  enumerated: types.boolean,
  enumerated_at: types.string,
  firmware_version: types.maybeNull(types.integer),
  gateway_bru_address: types.maybeNull(types.integer),
  gateway_id: types.maybeNull(types.integer),
  hardware_version: types.maybeNull(types.integer),

  serial_number_hex: types.maybeNull(types.string),
  sticker: types.maybeNull(types.string),
  _gateways_establishment_id: types.maybeNull(types.integer),
  _gateways_id: types.maybeNull(types.integer),
  latest_heartbeat_received_at: types.maybeNull(types.string),
});
