import DetailsDrawer from 'components/shared/SystemOffline/DetailsDrawer';
import Desktop from 'components/shared/SystemOffline/SystemOfflinePopup/desktop';
import Mobile from 'components/shared/SystemOffline/SystemOfflinePopup/mobile';
import { useMediaQueries, useStore, useSystemOfflineData } from 'hooks';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SystemOfflinePopup = () => {
  const { ui } = useStore();
  const { gateways, sensors, devices } = useSystemOfflineData();
  const { isMobileOrSmaller, isTabletOrGreater } = useMediaQueries();
  const [isPopupOpen, setIsPopupOpen] = useState(true);
  const [detailsDrawerOpen, setDetailsDrawerOpen] = useState(false);
  const [fullSystemOffline, setFullSystemOffline] = useState(false);
  const navigate = useNavigate();

  const { title, statusMessage } = useMemo(() => {
    const title = fullSystemOffline ? 'System Offline!' : 'Partial System Offline!';
    const statusMessage = fullSystemOffline
      ? 'We have detected that all of your equipment is offline'
      : 'We have detected that some of your equipment is offline:';

    return { title, statusMessage };
  }, [fullSystemOffline]);

  const offlineDeviceText = useMemo(() => {
    const sensorCount = sensors.offline.length;
    const gatewayCount = gateways.offline.length;

    const verb = sensorCount + gatewayCount > 1 ? 'are' : 'is';

    const gatewayText =
      gatewayCount === 1 ? '1 Gateway' : gatewayCount > 1 ? `${gatewayCount} Gateways` : '';
    const sensorText =
      sensorCount === 1 ? '1 Sensor' : sensorCount > 1 ? `${sensorCount} Sensors` : '';

    const conjunction = gatewayText && sensorText ? ' and ' : '';

    return `${gatewayText}${conjunction}${sensorText} ${verb} offline!`.trim();
  }, [sensors, gateways]);

  useEffect(() => {
    if (devices.offline.length > 0 && !ui.systemStatus.offline) {
      setIsPopupOpen(true);
    } else {
      setIsPopupOpen(false);
    }

    if (devices.total.length === devices.offline.length) {
      setFullSystemOffline(true);
    } else {
      setFullSystemOffline(false);
    }
  }, [devices, ui.systemStatus]);

  const handlePopupClose = () => {
    setIsPopupOpen(false);
    ui.setSystemOffline(true);
    ui.setFullSystemOffline(fullSystemOffline);
  };

  const handleDetailsClick = () => {
    setDetailsDrawerOpen(!detailsDrawerOpen);
    setIsPopupOpen(false);
    ui.setSystemOffline(true);
    ui.setFullSystemOffline(fullSystemOffline);
  };

  const closeDetailsDrawer = () => {
    setDetailsDrawerOpen(!detailsDrawerOpen);
  };

  const handleNavigateToHardwareManagement = () => {
    navigate('/system-configuration/topology-management');
  };

  return (
    <>
      {isTabletOrGreater && (
        <Desktop
          title={title}
          statusMessage={statusMessage}
          isPopupOpen={isPopupOpen}
          fullSystemOffline={fullSystemOffline}
          offlineDeviceText={offlineDeviceText}
          handlePopupClose={handlePopupClose}
          handleDetailsClick={handleDetailsClick}
        />
      )}
      {isMobileOrSmaller && (
        <Mobile
          title={title}
          statusMessage={statusMessage}
          isPopupOpen={isPopupOpen}
          fullSystemOffline={fullSystemOffline}
          offlineDeviceText={offlineDeviceText}
          handlePopupClose={handlePopupClose}
          handleDetailsClick={handleDetailsClick}
        />
      )}
      <DetailsDrawer
        isOpen={detailsDrawerOpen}
        handleClose={closeDetailsDrawer}
        handleNavigateToHardwareManagement={handleNavigateToHardwareManagement}
      />
    </>
  );
};

export default observer(SystemOfflinePopup);
