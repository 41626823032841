import React from 'react';
import { observer } from 'mobx-react';

import { Box, Tooltip } from '@mui/material';

import {
  Cellular,
  CellularFailed,
  EthernetOnline,
  EthernetFailed,
  WifiFailed,
  Wifi,
} from 'assets/icons';

import dateUtilities from 'utils/dateUtilities';
import palette from 'theme/palette';

const OnlineStatus = ({ date = null, gateway = null, iconStyles = {} }) => {
  let isoDate = null;
  let onlineState = false;
  let title = 'No Data';

  if (gateway) {
    // TO-DO: Refactor Gateway logic to leverage `latest_heartbeat_received_at` and `latest_sample_received_at`
    // this involves changing data sources and calculations to be timestamp driven rather than seconds
    //
    // CWA-690 has reference to resolve this issue

    const sampleAge = gateway.latest_heartbeat_age_seconds_min;
    const heartbeatAge = gateway.latest_sample_age_seconds_min;

    const mostRecentAge = [sampleAge, heartbeatAge]
      .filter(age => age !== null)
      .reduce((min, age) => (min === null || age < min ? age : min), null);

    onlineState = mostRecentAge !== null && mostRecentAge < 300;

    const currentTimestamp = Date.now();
    const adjustedTimestamp =
      mostRecentAge !== null ? currentTimestamp - mostRecentAge * 1000 : currentTimestamp;

    isoDate = new Date(date || adjustedTimestamp).toISOString();

    title = !mostRecentAge
      ? 'No Data'
      : dateUtilities.formatDateIncludingToday(new Date(isoDate), {
          applyEstablishmentOffset: false,
        });
  } else {
    if (date) {
      isoDate = new Date(date).toISOString();
      onlineState = Math.round((Date.now() - Date.parse(isoDate)) / 1000 / 60) < 5;
      title = !date
        ? 'No Data'
        : dateUtilities.formatDateIncludingToday(new Date(isoDate), {
            applyEstablishmentOffset: false,
          });
    } else {
      // fallback to default values
    }
  }

  let iconBox = (
    <Box
      sx={{
        position: 'absolute',
        top: '8px',
        right: '8px',
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        backgroundColor: onlineState ? palette.status.green : palette.status.red,
        ...iconStyles,
      }}
    />
  );

  if (gateway?.metadata?.network_connectivity_mode) {
    const icon_map = {
      wifi: onlineState ? <Wifi /> : <WifiFailed />,
      cellular: onlineState ? <Cellular /> : <CellularFailed />,
      ethernet: onlineState ? <EthernetOnline className="mt" /> : <EthernetFailed className="mt" />,
    };

    iconBox = (
      <Box
        sx={{
          position: 'absolute',
          top: '8px',
          right: '16px',
          width: '10px',
          height: '10px',
          ...iconStyles,
        }}
      >
        {icon_map[gateway.metadata.network_connectivity_mode]}
      </Box>
    );
  }

  return <Tooltip title={title}>{iconBox}</Tooltip>;
};

export default observer(OnlineStatus);
