import { types } from 'mobx-state-tree';

const DeviceModel = types.model({
  id: types.identifierNumber,
  from_ts: types.string,
  to_ts: types.string,
  isActive: types.boolean,
  date: types.string,
  value: types.integer,
});

export const DeviceConnectivityHistory = types.model({
  gateways: types.array(DeviceModel),
  brus: types.array(DeviceModel),
  sensors: types.array(DeviceModel),
});
