import {
  CheckCircle,
  Close,
  HelpOutline,
  InfoOutlined,
  Refresh,
  Warning,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import TroubleshootModal from 'components/shared/SystemOffline/SystemOfflineBanner/mobile/TroubleshootModal';
import { useState } from 'react';
import { stringUtilities } from 'utils';

const SystemOfflineBanner = ({
  fullSystemOffline,
  systemRestored,
  exitBanner,
  refreshTimeLeft,
  handleRefresh,
  isRefreshing,
  onlineCountdown,
  isBackOnline,
  handleDetailsClick,
}) => {
  const [troubleshootingModalOpen, setTroubleshootingModalOpen] = useState(false);

  const handleTroubleshootingClick = () => {
    setTroubleshootingModalOpen(true);
  };

  return (
    <>
      <StyledRootDiv className={clsx({ restored: systemRestored })}>
        <div className={'menuOptions'}>
          <span className={'menuTitle'}>
            {systemRestored ? (
              <CheckCircle />
            ) : (
              <Warning sx={{ width: '24px' }} htmlColor={'#000'} />
            )}
            {systemRestored
              ? 'System back online!'
              : fullSystemOffline
              ? 'System offline!'
              : 'Partial system offline!'}
          </span>
          {systemRestored ? (
            <span className={'menuOption online'}>
              <span className={clsx('timer online', { active: isBackOnline })}>
                {stringUtilities.formatTimer(onlineCountdown)}
              </span>
              <span className={'menuText'} onClick={exitBanner}>
                <Close />
              </span>
            </span>
          ) : (
            <div className={'menuButtons'}>
              <span className={'menuOption'} onClick={handleTroubleshootingClick}>
                <HelpOutline htmlColor={'#000'} />
              </span>
              <span className={'menuOption'} onClick={handleDetailsClick}>
                <InfoOutlined htmlColor={'#000'} />
              </span>
              {isRefreshing ? (
                <span className={clsx('menuOption timer refresh', { active: isRefreshing })}>
                  {stringUtilities.formatTimer(refreshTimeLeft)}
                </span>
              ) : (
                <span onClick={handleRefresh} className={'menuOption'}>
                  <Refresh htmlColor={'#000'} />
                </span>
              )}
            </div>
          )}
        </div>
      </StyledRootDiv>
      <TroubleshootModal
        open={troubleshootingModalOpen}
        onClose={() => setTroubleshootingModalOpen(false)}
      />
    </>
  );
};

const StyledRootDiv = styled('div')(({ theme: { palette } }) => ({
  height: '40px',
  backgroundColor: palette.status.red,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&.restored': {
    backgroundColor: palette.status.green,
  },
  '.menuOptions': {
    display: 'flex',
    width: '100%',
    color: palette.black,
    margin: '0 24px',
  },
  '.menuTitle': {
    display: 'flex',
    alignItems: 'center',
  },
  '.menuButtons': {
    display: 'flex',
    marginLeft: 'auto',
  },
  '.menuOption': {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '16px',
    '&.online': {
      marginLeft: 'auto',
    },
  },
  '.menuOptionText': {
    color: palette.black,
  },
  '.timer': {
    backgroundColor: palette.black,
    borderRadius: '10px',
    fontSize: '12px',
    lineHeight: '16px',
    padding: '0 8px',
    opacity: 0,
    transition: 'opacity 0.5s',
    width: '49px',
    '&.online': {
      color: palette.status.green,
    },
    '&.refresh': {
      color: palette.status.red,
    },
    '&.active': {
      opacity: 1,
    },
  },
}));

export default SystemOfflineBanner;
