import { types } from 'mobx-state-tree';

const Metadata = types.model({
  installation_location: types.optional(types.maybeNull(types.string), ''),
  network_connectivity_check: types.optional(types.boolean, false),
  network_ip_assignment_type: types.optional(types.maybeNull(types.string), ''),
  manufacturer: types.optional(types.maybeNull(types.string), ''),
  model_number: types.optional(types.maybeNull(types.string), ''),
  serial_number: types.optional(types.maybeNull(types.string), ''),
  network_connectivity_mode: types.maybeNull(
    types.enumeration('network_connectivity_mode', ['wifi', 'cellular', 'ethernet']),
  ),
});

export const Gateway = types.model({
  id: types.integer,
  cooler_id: types.maybeNull(types.integer),
  identifier: types.string,
  name: types.maybeNull(types.string),
  type_id: types.maybeNull(types.integer),
  _coolers_name: types.maybeNull(types.string),
  _gateway_types_name: types.maybeNull(types.string),
  metadata: types.maybeNull(Metadata),
  latest_sample_received_at: types.maybeNull(types.string),
});
