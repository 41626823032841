import { useStore } from 'hooks';
import { useMemo } from 'react';

const useSystemOfflineData = () => {
  const { topologyManagementStore } = useStore();

  const coolers = topologyManagementStore.getFilteredItemsByKey('cooler');
  const gateways = topologyManagementStore.getFilteredItemsByKey('gateway');
  const brus = topologyManagementStore.getFilteredItemsByKey('bru');
  const sensors = topologyManagementStore.getFilteredItemsByKey('sensor');

  const gatewayStatusList = useMemo(() => {
    return gateways.map(gateway => {
      const cooler = coolers.find(({ id }) => id === gateway.cooler_id);
      const { latest_sample_age_seconds_max, latest_heartbeat_age_seconds_max } = gateway;
      const timestamp = latest_sample_age_seconds_max || latest_heartbeat_age_seconds_max;
      const isOnline = timestamp ? Math.floor(timestamp / 60) < 5 : !!timestamp;
      return { ...gateway, isOnline, cooler };
    });
  }, [gateways, coolers]);

  const bruStatusList = useMemo(() => {
    return brus.map(bru => {
      const { latest_heartbeat_received_at } = bru;
      const isOnline =
        Math.floor((Date.now() - new Date(latest_heartbeat_received_at).getTime()) / 1000) < 300;
      return { ...bru, isOnline };
    });
  }, [brus]);

  const sensorStatusList = useMemo(
    () =>
      sensors.map(sensor => {
        const { latest_heartbeat_received_at, bru_id, _gateways_id } = sensor;
        const bru = brus.find(({ id }) => id === bru_id);
        const gateway = gateways.find(({ gateway_id }) => gateway_id === _gateways_id);
        const cooler = coolers.find(({ id }) => id === gateway.cooler_id);

        const isOnline =
          (Date.now() - new Date(latest_heartbeat_received_at).getTime()) / 1000 < 300;

        return {
          ...sensor,
          isOnline,
          gateway,
          bru,
          gateway_bru_address: bru?.gateway_bru_address,
          gateway_name: gateway?.name,
          cooler,
        };
      }),
    [brus, gateways, sensors, coolers],
  );

  return {
    gateways: {
      total: gateways.length,
      offline: gatewayStatusList.filter(gateway => gateway.isOnline === false),
    },
    brus: {
      total: brus.length,
      offline: bruStatusList.filter(bru => bru.isOnline === false),
    },
    sensors: {
      total: sensors.length,
      offline: sensorStatusList.filter(sensor => sensor.isOnline === false),
    },
    devices: {
      total: gateways.length + sensors.length,
      offline: [...gatewayStatusList, ...sensorStatusList].filter(
        device => device.isOnline === false,
      ),
    },
  };
};

export default useSystemOfflineData;
