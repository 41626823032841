import { PeopleAlt } from '@mui/icons-material';

import { JarIcon, CleanIcon, FiltersIcon, UserIcon, KegIcon } from 'assets/icons';
import { uniq } from 'lodash';

const itemEventsStatuses = {
  0: 'Added',
  11: 'Sold',
  12: 'Expired',
  13: 'Empty',
  14: 'Mistake',
  15: 'Infected',
  7: 'Kicked',
  5: 'Tapped',
  2: 'Saved',
  10: 'De-Queued',
  6: 'Queued',
  16: 'Swapped',
  line_disconnect_current_item: 'Un-Tapped Keg',
  line_next_item: 'Tapped Keg',
  line_connect_new_item: 'Tapped Keg',
  remove_items: 'Removed Inventory',
  add_items: 'Added Inventory',
  adjust_keg_level: 'Keg Level Adjusted',
  updateGenericElement: 'Modified Keg Details',
};

export const icon = {
  items: <KegIcon />,
  bars_role_users: <PeopleAlt />,
  beverages: <JarIcon />,
  cleanings: <CleanIcon />,
  containers: <FiltersIcon />,
  establishments: <FiltersIcon />,
  lines: <FiltersIcon />,
  pos_skus: <UserIcon />,
  skus: <UserIcon />,
  report_configurations: <FiltersIcon />,
  role_users: <PeopleAlt />,
  users: <PeopleAlt />,
};

export const eventTypeMap = {
  bars_role_users: 'User',
  beverages: 'Beverages',
  cleanings: 'Cleanings',
  containers: 'Containers',
  establishments: 'Establishment',
  items: 'Items',
  item_lines: 'Lines',
  lines: 'System Configuration',
  pos_skus: 'POS SKU(s)',
  skus: 'SKU(s)',
  report_configurations: 'System Configuration',
  role_users: 'Employees',
  users: 'Users',
};

export const appliedToFieldMap = {
  users: 'display_name',
  containers: 'name',
  producers: 'name',
  establishments: 'name',
  skus: 'name',
  pos_skus: 'item',
  lines: 'line_identifier',
  taps: 'identifier',
  coolers: 'name',
};

export const eventsFilterList = [
  'Line Cleaned Removed',
  'Line Cleaning Started',
  'Line Cleaning Stopped',
  'Container Units Modified',
  'Container Created',
  'Container Deleted',
  'Container Details Modified',
  'System Configuration Modified',
  'Inventory Added',
  'Keg Level Adjusted',
  'Inventory Removed',
  'Keg Details Modified',
  'Cleaning Statistics Reset',
  'Cleaning Scheduled',
  'Beverage Producer Created',
  'Employee Role Modified',
  'Invite Accepted',
  'Employee Invited',
  'Employee Access Modified',
  'Re-Sent Invite',
  'Keg Size Created',
  'Serving/Keg Size Added',
  'Serving/Keg Size Deleted',
];

export const events = {
  bars_role_users: {
    generic_insert: 'Modified Employee Access',
    generic_delete: 'Modified Employee Access',
    deleteGenericElement: 'Modified Employee Access',
    deleteGenericCollection: 'Modified Employee Access',
  },
  beverages: {
    I: {
      add_items: 'Beverage Created',
      copy_beverages: 'Beverage Added',
      create_beverages: 'Beverage Created',
    },
    U: {
      updateGenericElement: 'Beverage Details Modified',
    },
    // generic_update: 'Modified Beverage Details',
    // updateGenericCollection: 'Modified Beverage Details',
  },
  cleanings: {
    I: {
      clean_add: 'Line Cleaning Added',
      clean_modify: 'Modified Line Cleaning log(s)',
      clean_start: 'Line Cleaning Started',
    },
    U: {
      clean_add: 'Line Cleaning Modified',
      clean_modify: 'Line Cleaning Modified',
      clean_stop: 'Line Cleaning Stopped',
    },
    D: {
      clean_add: 'Line Cleaned Removed',
      clean_modify: 'Line Cleaned Removed',
      clean_remove: 'Line Cleaned Removed',
    },
  },
  containers: {
    I: {
      createGeneric: 'Container Created',
    },
    U: {
      change_container_units: 'Container Units Modified',
      createGeneric: 'Container Details Modified',
    },
    D: {
      deleteGenericElement: 'Container Deleted',
    },
  },
  establishments: {
    U: {
      updateGenericElement: 'System Configuration Modified',
    },
  },
  items: {
    U: {
      adjust_keg_level: 'Keg Level Adjusted',
      remove_items: 'Inventory Removed',
      updateGenericCollection: 'Keg Details Modified',
      updateGenericElement: 'Keg Details Modified',
      // applyItemLineFixes: '',
      // line_connect_new_item: '',
      // line_disconnect_current_item: '',
      // line_next_item: '',
    },
    I: {
      add_items: 'Inventory Added',
    },
  },
  item_lines: {
    itemline_remove: 'Removed Keg from Queue',
    itemline_reorder: 'Modified Keg Queue',
    line_next_item: 'Tapped Keg',
    line_disconnect_current_item: 'Un-Tapped Keg',
  },
  lines: {
    U: {
      clean_reset_statistics: 'Cleaning Statistics Reset',
      clean_schedule: 'Cleaning Scheduled',
      updateGenericCollection: 'Update Lines Details',
    },
  },
  pos_skus: {
    U: {
      updateGenericCollection: 'Modified POS SKU(s)',
    },
    generic_update: 'Modified POS SKU(s)', // need revalidate names
    updateGenericElement: 'Modified POS SKU(s)',
  },
  producers: {
    I: {
      createGeneric: 'Beverage Producer Created',
    },
  },
  skus: {
    I: {
      add_items: 'Keg Size Created',
      createGeneric: 'Serving/Keg Size Added',
    },
    D: {
      deleteGeneric: 'Serving/Keg Size Deleted',
      deleteGenericElement: 'Serving/Keg Size Deleted',
    },
    U: {
      updateGenericElement: 'Serving/Keg Size Deleted',
    },
  },
  role_users: {
    U: {
      updateGenericElement: 'Employee Role Modified',
      user_accept_grant: 'Invite Accepted',
      user_lock_grant: 'Employee Access Modified',
      user_resend_grant: 'Re-Sent Invite',
    },
    I: {
      user_create_grant: 'Employee Invited',
    },
    D: {
      user_delete_role: 'Employee Access Modified',
    },
  },
  // users: {
  //   user_create_grant: 'Invited User',
  //   user_delete_grant: 'Archived User',
  //   // user_delete_grant: 'Cancelled User Invite', // collision
  //   user_lock_grant: 'Archived User',
  //   user_resend_grant: 'Re-Sent Invitation',
  //   user_authenticate: 'Logged In',
  // },
};

export const generateEvent = itemEvent => {
  if (itemEvent.collection_name === 'pos_skus') {
    let keys = [];
    if (itemEvent?.change_before?.keys()) {
      keys.push(...Array.from(itemEvent?.change_before?.keys() || []));
    }
    if (itemEvent?.change_after?.keys()) {
      keys.push(...Array.from(itemEvent?.change_after?.keys() || []));
    }

    if (uniq(keys).includes('sku_id')) {
      if (itemEvent?.change_before?.get('sku_id')) return 'Un-Mapped POS SKU(s)';
      if (itemEvent?.change_after?.get('sku_id')) return 'Mapped POS SKU(s)';
    }

    if (itemEvent?.change_before?.get('ignore') === undefined) {
      console.log('undefined ignore', itemEvent);
    }

    if (uniq(keys).includes('ignore')) {
      if (itemEvent?.change_before?.get('ignore')) {
        return 'Un-Ignored POS SKU(s)';
      } else {
        return 'Ignored POS SKU(s)';
      }
    }
  } else {
    return itemEvent
      ? events[itemEvent.collection_name][itemEvent.change_type][itemEvent.operation] ||
          itemEvent.operation
      : '-';
  }
};

export default itemEventsStatuses;
