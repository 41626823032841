import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Box, Popover, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isBefore, differenceInDays } from 'date-fns';

import { StaticDatePicker } from '@mui/x-date-pickers';
import { DateField } from '@mui/x-date-pickers';

const CustomDatePicker = forwardRef(({ anchor, onClose, onConfirm }, ref) => {
  const [error, setError] = useState(false);
  const [period, setPeriod] = useState({
    from: null,
    to: null,
  });
  const [periodError, setPeriodError] = useState('');

  const handleChangeFrom = value => {
    setPeriod(prevState => ({
      ...prevState,
      from: value,
    }));
  };

  const handleChangeTo = value => {
    if (isBefore(value, period.from)) {
      setPeriod(prevState => ({
        from: value,
        to: value,
      }));
    } else {
      setPeriod(prevState => ({
        ...prevState,
        to: value,
      }));
    }
  };

  const handleConfirm = () => {
    if (differenceInDays(period.to, period.from) > 7) {
      setPeriodError('Period cannot be more than 7 days');
    } else {
      onConfirm({
        from: period.from,
        to: period.to,
      });
    }
  };

  const handleClose = () => {
    setPeriodError('');
    onClose();
  };

  useImperativeHandle(ref, () => ({
    reset() {
      setPeriod({
        from: null,
        to: null,
      });
    },
  }));

  return (
    <Popover
      open={Boolean(anchor)}
      anchorEl={anchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <StyledRootBox>
        <Box className="pickers_box">
          <StaticDatePicker
            className="date_picker"
            maxDate={period.to || new Date()}
            slots={{ actionBar: null, toolbar: null }}
            onChange={handleChangeFrom}
            value={period.from}
            minDate={new Date(2000, 0)}
          />
          <StaticDatePicker
            className="date_picker"
            disableFuture
            slots={{ actionBar: null, toolbar: null }}
            onChange={handleChangeTo}
            value={period.to}
            minDate={new Date(2000, 0)}
          />
        </Box>
        <Box className="error_box">
          {Boolean(periodError) && <Typography className="error_text">{periodError}</Typography>}
        </Box>
        <Box className="controls_box">
          <Box className="input_box">
            <DateField
              value={period.from}
              disableFuture
              className="date_input"
              onChange={handleChangeFrom}
              onError={error => setError(error)}
            />
            <Typography>to</Typography>
            <DateField
              value={period.to}
              disableFuture
              maxDate={period.to || new Date()}
              className="date_input"
              onChange={handleChangeTo}
              onError={error => setError(error)}
            />
          </Box>

          <Box className="button_box">
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              disabled={!Boolean(period.to) || !Boolean(period.from) || Boolean(error)}
              variant="contained"
              onClick={handleConfirm}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </StyledRootBox>
    </Popover>
  );
});

export default CustomDatePicker;

const StyledRootBox = styled(Box)(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',

  '.pickers_box': {
    display: 'flex',
    flexDirection: 'row',
  },

  '.error_box': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 12px',
    '.error_text': {
      color: palette.status.red,
    },
  },

  '.date_picker': {
    '& .MuiDialogActions-root': {
      display: 'none',
    },

    '& .MuiButtonBase-root': {
      padding: '12px',
    },

    '& .MuiPickersDay-root.Mui-disabled': {
      color: palette.whiteEmphasis.low,
    },
  },

  '.controls_box': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '18px',
  },

  '.input_box': {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '20px',
    alignItems: 'center',
    width: '50%',
    height: '36px',
    maxWidth: '400px',
  },

  '.date_input': {
    '& .MuiInputBase-input': {
      padding: '10px',
    },
  },

  '.button_box': {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '20px',
  },
}));
