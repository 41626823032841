import React, { useCallback, useState, useMemo } from 'react';
import { observer } from 'mobx-react';

import { DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import Dialog from 'components/shared/dialogs/Dialog';
import { useStore, useToast } from 'hooks';
import { messages } from 'config/messages';

const EditGatewayLocation = ({ open, onClose, onSuccess, gateway }) => {
  const { topologyManagementStore } = useStore();
  const { successToast, errorToast } = useToast();

  const [location, setLocation] = useState(gateway.metadata?.installation_location || '');

  const handleSuccess = useCallback(
    result => {
      if (onSuccess instanceof Function) {
        onSuccess(result);
      }
    },
    [onSuccess],
  );

  const handleSubmit = useCallback(
    async e => {
      const { id, metadata } = gateway;
      try {
        const response = await topologyManagementStore.patchGateway(id, {
          metadata: {
            ...metadata,
            installation_location: location,
          },
        });
        successToast(messages.gateway.LOCATION_UPDATE_SUCCESS);
        handleSuccess(response);
      } catch (error) {
        errorToast(messages.gateway.LOCATION_UPDATE_ERROR);
      } finally {
        onClose(e);
      }
    },
    [gateway, location, onClose, handleSuccess, successToast, errorToast, topologyManagementStore],
  );

  const isValid = useMemo(() => {
    if (gateway.metadata?.installation_location)
      return gateway.metadata?.installation_location !== location;
    else return !!location;
  }, [gateway.metadata?.installation_location, location]);

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      fullWidth
      title={'Edit Gateway Installation Location'}
    >
      <DialogContent>
        <TextField
          fullWidth
          variant="outlined"
          label="Gateway Location"
          name="gateway_location"
          type={'string'}
          value={location}
          onChange={({ target: { value } }) => setLocation(value)}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>

        <Button
          disabled={!isValid}
          variant="contained"
          color="primary"
          onClick={e => handleSubmit(e)}
        >
          Confirm
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default observer(EditGatewayLocation);

const StyledDialog = styled(Dialog)(({ theme: { breakpoints, palette } }) => ({
  width: '450px',
  zIndex: '1202 !important',

  [breakpoints.down('sm')]: {
    width: '100%',
  },

  '.body': {
    '& > :not(:last-child)': {
      marginBottom: '10px',
    },
  },
}));
