import { QuestionMark } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Link, Modal, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const troubleshootingSteps = [
  {
    title: 'Ensure power connectivity',
    description:
      'Verify that all power connections are securely plugged in to ensure proper functionality of the device.',
  },
  {
    title: 'Power cycle',
    description:
      'Restart the device by turning if off, waiting a few seconds, and then turning it back on. This can often resolve temporary issues or glitches.',
  },
  {
    title: 'Connect with Customer Support',
    description: (
      <>
        If you're experiencing difficulties that cannot be resolved through troubleshooting, reach
        out to our customer <Link>support team</Link> for assistance and guidance.,
      </>
    ),
  },
];

const TroubleshootModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <StyledRootDiv className={'SystemOfflinePopup'}>
        <div className={`closeIcon`}>
          <CloseIcon onClick={onClose} />
        </div>
        <div className={'troubleshootTitle'}>
          <QuestionMark className={'questionMarkIcon'} />
          <Typography variant="h5">Troubleshooting</Typography>
        </div>
        <ol className={'troubleshootSteps'}>
          {troubleshootingSteps.map((step, index) => {
            return (
              <li key={index} className={'troubleshootStep'}>
                <div className={'ordinalContainer'}>
                  <Typography variant="h8" className={'ordinal'}>
                    {index + 1}
                  </Typography>
                </div>
                <div>
                  <Typography className={'troubleshootStepTitle'} variant="body1">
                    {step.title}
                  </Typography>
                  <Typography variant="body2">{step.description}</Typography>
                </div>
              </li>
            );
          })}
        </ol>
      </StyledRootDiv>
    </Modal>
  );
};

export default TroubleshootModal;

const StyledRootDiv = styled('div')(({ theme: { palette, breakpoints, spacing } }) => ({
  backgroundColor: palette.background.default,
  padding: '20px',
  borderRadius: '8px',
  position: 'fixed',
  inset: 0,
  maxWidth: '560px',
  width: 'fit-content',
  height: 'fit-content',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',

  '.closeIcon': {
    alignSelf: 'end',
  },
  '.questionMarkIcon': {
    border: '2px solid white',
    borderRadius: '50%',
    marginRight: '12px',
  },
  '.troubleshootTitle': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '32px',
  },
  '.troubleshootSteps': {
    padding: 0,

    '.troubleshootStep': {
      borderBottom: `1px solid ${palette.outline.main}`,
      padding: '24px 0',
      display: 'flex',

      '&:last-child': {
        borderBottom: 'none',
      },
      '.ordinalContainer': {
        marginRight: '24px',
        marginLeft: '4px',
      },
      '.ordinal': {
        color: palette.primary.main,
        backgroundColor: '#2C1F13',
        height: '24px',
        width: '24px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
      },
      '.troubleshootStepTitle': {
        marginBottom: '8px',
      },
    },
  },
}));
