import React, { useState, useRef } from 'react';
import { observer } from 'mobx-react';

import clsx from 'clsx';
import { format, subDays, isToday } from 'date-fns';

import { Button, Box, IconButton, Typography, styled } from '@mui/material';
import { Close } from '@mui/icons-material';

import { useStore } from 'hooks';

import CustomDatePicker from './CustomDatePicker';

const PeriodButtonGroup = ({ className }) => {
  const { topologyManagementStore } = useStore();

  const [activePeriod, setActivePeriod] = useState('1');
  const [datePickerAnchor, setDatePickerAnchor] = useState(null);
  const [badgePeriod, setBadgePeriod] = useState(null);

  const ref = useRef(null);

  const handleChangePeriod = event => {
    setActivePeriod(event.target.value);
    topologyManagementStore.setPeriod({
      from: format(subDays(new Date(), event.target.value), 'yyyy-MM-dd HH:mm:ss'),
      to: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    });

    ref?.current?.reset();
    setBadgePeriod(null);
    setDatePickerAnchor(null);
  };

  const isActive = value => {
    return activePeriod === value;
  };

  const handleSelectCustom = event => {
    setActivePeriod('custom');
    setDatePickerAnchor(event.currentTarget);
  };

  const handleConfirmCustomPeriod = period => {
    topologyManagementStore.setPeriod({
      from: format(new Date(period.from), 'yyyy-MM-dd HH:mm:ss'),
      to: format(isToday(period.to) ? new Date() : new Date(period.to), 'yyyy-MM-dd HH:mm:ss'),
    });

    setBadgePeriod({
      from: format(new Date(period.from), 'MMM dd'),
      to: format(new Date(period.to), 'MMM dd'),
    });

    setDatePickerAnchor(null);
  };

  const handleCloseCustomDatePicker = () => {
    // A little trick to reuse function
    handleChangePeriod({ target: { value: '1' } });
  };

  return (
    <StyledRootBox className={className}>
      {activePeriod === 'custom' && badgePeriod && (
        <Box className="period_badge">
          <Typography className="badge_title">{`${badgePeriod.from} - ${badgePeriod.to}`}</Typography>
          <IconButton className="badge_icon" onClick={handleCloseCustomDatePicker}>
            <Close />
          </IconButton>
        </Box>
      )}

      <Box elevation={0} className="paper_box">
        <Button
          value={1}
          className={clsx('period_button', { active: isActive('1') })}
          onClick={handleChangePeriod}
        >
          24H
        </Button>
        <Button
          value={7}
          className={clsx('period_button', { active: isActive('7') })}
          onClick={handleChangePeriod}
        >
          7D
        </Button>
        <Button
          value={'custom'}
          className={clsx('period_button', { active: isActive('custom') })}
          onClick={handleSelectCustom}
        >
          Custom
        </Button>

        <CustomDatePicker
          ref={ref}
          anchor={datePickerAnchor}
          onClose={handleCloseCustomDatePicker}
          onConfirm={handleConfirmCustomPeriod}
        />
      </Box>
    </StyledRootBox>
  );
};

export default observer(PeriodButtonGroup);

const StyledRootBox = styled(Box)(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: palette.background.main,
  borderRadius: '8px',

  '.period_badge': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '36px',
    padding: '6px 10px',
    backgroundColor: palette.background.overlay2,
    marginRight: '8px',
  },

  '.badge_title': {
    fontSize: '11px',
    color: palette.whiteEmphasis.high,
    marginRight: '8px',
  },

  '.badge_icon': {
    width: '18px',
    height: '18px',
    backgroundColor: palette.background.overlay8,

    '& svg': {
      width: '14px',
      height: '14px',
      color: '#151515',
    },
  },

  '.paper_box': {
    borderRadius: '8px',
    padding: '2px',
    height: 'fit-content',
  },

  '.period_button': {
    borderRadius: '8px',
    height: '24px',
    textTransform: 'uppercase',
    fontSize: '10px',
    color: palette.whiteEmphasis.medium,
    minWidth: '70px',

    '&.active': {
      backgroundColor: palette.background.overlay8,
      color: palette.whiteEmphasis.high,
    },

    '&:not(:last-of-type) ': {
      marginRight: '2px',
    },
  },
}));
